
@lisu-border-color: rgb(137, 137, 137);

body {
    font-family: 'Roboto', sans-serif;
    background-color: rgb(170, 197, 210);
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
}

pre, code, .code, tt, .mono {
    font-family: 'Victor Mono', monospace;
}

.code {
    font-size: 12px;
    font-weight: 400;
    background-color: rgba(128, 128, 128, 0.25);
    display: inline-block;
    padding: 1px 4px;
    border-radius: 4px;
    font-weight: bold;
}

.lisu-borders {
    border: 1px solid @lisu-border-color;
    border-bottom: 3px solid @lisu-border-color;
    border-radius: 8px;
}

.lisu-borders-transparent {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.lisu-borders-smaller {
    border: 1px solid @lisu-border-color;
    border-bottom: 3px solid @lisu-border-color;
    border-radius: 5px;
}

.lisu-full-page-error {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

.lisu-card {
    box-sizing: border-box;
    .lisu-borders();
    padding: 16px;
    background-color: white;
}

.lisu-logo {
    font-size: 64px;
    font-family: 'Victor Mono', monospace;
    font-weight: bold;
    text-align: center;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, 1px 1px 0 black, -1px 1px 0 black,
                0 2px 0 black, 0 3px 0 black,
                -1px 2px 0 black, -1px 3px 0 black,
                1px 2px 0 black, 1px 3px 0 black;
    color: skyblue;
}

.lisu-logo-small {
    font-size: 16px;
    font-family: 'Victor Mono', monospace;
    font-weight: bold;
    text-align: center;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, 1px 1px 0 black, -1px 1px 0 black,
                0 2px 0 black, 0 3px 0 black,
                -1px 2px 0 black, -1px 3px 0 black,
                1px 2px 0 black, 1px 3px 0 black;
}


.form-control, .form-select {
    box-sizing: border-box;
    .lisu-borders-smaller();
    font-size: 15px;
    padding: 2px 8px;
}

.form-control:focus,
.form-control:active {
    outline: none;
    box-shadow: none;
}

[class^="ri-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: 1em;
    line-height: 100%;
    vertical-align: middle;
    margin-top: -0.2em;
}

.btn {
    .lisu-borders-transparent();
}

.btn-xsm {
    padding: 2px 3px;
    line-height: 100%;
    font-size: 11px;
    
    [class^="ri-"] {
        font-size: 14px;
        margin-top: -0.3em;
    }
}

#base {
    height: 100%;
}

.lisu-ui-wrapper {
    height: 100%;
    max-height: calc(100% - 53px);
}

.lisu-sidebar {
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
}

.lisu-content {
    height: 100%;
    overflow-y: scroll;
}

.lisu-nav-item {
    display: block;
    font-size: 20px;
    font-weight: 300;
    text-decoration: none !important;
    color: rgba(255, 255, 255, 0.95);
    cursor: pointer;
    padding: 0.5em 1em;
    
    &:not(.sub) {
        clip-path: polygon(0 0, 80% 0, 95% 50%, 80% 100%, 0 100%);
    }
    
    &.sub {
        font-size: 16px;
        padding: 0.3em;
        padding-left: 3em;
    }
    
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
    
    &[data-active="true"] {
        color: deepskyblue;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.lisu-ui-side-list {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    border-right: 1px solid @lisu-border-color;
    
    .lisu-side-list-item {
        display: block;
        padding: 8px;
        border-bottom: 1px solid @lisu-border-color;
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lisu-smaller {
    font-size: 80%;
}

.lisu-small-label {
    font-size: 80%;
    color: rgba(128, 128, 128, 0.66);
    text-transform: uppercase;
}


.ld-app-date-picker-wrapper {
    position: relative;
}

.ld-app-date-picker {
    position: absolute;
    left: 0;
    background-color: white;
    .lisu-borders();
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 10000;
    
    .ld-app-calendar {
        width: 100%;
        user-select: none;
        
        th {
            border-bottom: 1px solid gray;
            width: calc(100% / 8);
        }
        
        td {
            font-size: 12px;
            font-weight: bold;
            
            span {
                display: grid;
                width: 20px;
                height: 20px;
                line-height: 100%;
                align-items: center;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                border-radius: 50%;
                margin: 2px;
                
                &:hover {
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.2);
                }
                
                &[data-today="true"] {
                    border: 1px dashed black;
                }
                
                &[data-active="true"] {
                    background-color: deepskyblue;
                    color: white;
                }
            }
        }
        
        tr td:first-child {
            font-size: 70%;
            font-weight: 400;
            border-right: 1px solid gray;
            padding: 2px 8px;
        }
    }
}

.lisu-custom-check {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.15em;
    
    &:hover {
        transform: scale(1.1);
    }
}

.lisu-progress-step {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
}

.lisu-progress-step-label {
    cursor: pointer;
}

.lisu-progress-step-line {
    width: 100%;
    height: 3px;
    transform: translateX(50%) translateY(16px);
    transform: translateX(50%) translateY(17px);
}

.lisu-hoverable-item {
    display: block;
    padding: 4px;
    user-select: none;
    
    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
}