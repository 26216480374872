body {
  background-color: #aac5d2;
  width: 100%;
  height: 100vh;
  font-family: Roboto, sans-serif;
  overflow-y: hidden;
}

pre, code, .code, tt, .mono {
  font-family: Victor Mono, monospace;
}

.code {
  background-color: #80808040;
  border-radius: 4px;
  padding: 1px 4px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.lisu-borders {
  border: 1px solid #898989;
  border-bottom-width: 3px;
  border-radius: 8px;
}

.lisu-borders-transparent {
  border: 1px solid #0000004d;
  border-bottom-width: 3px;
  border-radius: 4px;
}

.lisu-borders-smaller {
  border: 1px solid #898989;
  border-bottom-width: 3px;
  border-radius: 5px;
}

.lisu-full-page-error {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.lisu-card {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #898989;
  border-bottom-width: 3px;
  border-radius: 8px;
  padding: 16px;
}

.lisu-logo {
  text-align: center;
  text-shadow: -1px -1px #000, 1px -1px #000, 1px 1px #000, -1px 1px #000, 0 2px #000, 0 3px #000, -1px 2px #000, -1px 3px #000, 1px 2px #000, 1px 3px #000;
  color: #87ceeb;
  font-family: Victor Mono, monospace;
  font-size: 64px;
  font-weight: bold;
}

.lisu-logo-small {
  text-align: center;
  text-shadow: -1px -1px #000, 1px -1px #000, 1px 1px #000, -1px 1px #000, 0 2px #000, 0 3px #000, -1px 2px #000, -1px 3px #000, 1px 2px #000, 1px 3px #000;
  font-family: Victor Mono, monospace;
  font-size: 16px;
  font-weight: bold;
}

.form-control, .form-select {
  box-sizing: border-box;
  border: 1px solid #898989;
  border-bottom-width: 3px;
  border-radius: 5px;
  padding: 2px 8px;
  font-size: 15px;
}

.form-control:focus, .form-control:active {
  box-shadow: none;
  outline: none;
}

[class^="ri-"] {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  margin-top: -.2em;
  font-size: 1em;
  line-height: 100%;
  display: inline-block;
}

.btn {
  border: 1px solid #0000004d;
  border-bottom-width: 3px;
  border-radius: 4px;
}

.btn-xsm {
  padding: 2px 3px;
  font-size: 11px;
  line-height: 100%;
}

.btn-xsm [class^="ri-"] {
  margin-top: -.3em;
  font-size: 14px;
}

#base {
  height: 100%;
}

.lisu-ui-wrapper {
  height: 100%;
  max-height: calc(100% - 53px);
}

.lisu-sidebar {
  border-right: 1px solid #00000080;
  height: 100%;
}

.lisu-content {
  height: 100%;
  overflow-y: scroll;
}

.lisu-nav-item {
  color: #fffffff2;
  cursor: pointer;
  padding: .5em 1em;
  font-size: 20px;
  font-weight: 300;
  display: block;
  text-decoration: none !important;
}

.lisu-nav-item:not(.sub) {
  clip-path: polygon(0 0, 80% 0, 95% 50%, 80% 100%, 0 100%);
}

.lisu-nav-item.sub {
  padding: .3em .3em .3em 3em;
  font-size: 16px;
}

.lisu-nav-item:hover {
  background-color: #ffffff1a;
}

.lisu-nav-item[data-active="true"] {
  color: #00bfff;
  background-color: #0000004d;
  font-weight: bold;
}

.lisu-ui-side-list {
  border-right: 1px solid #898989;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.lisu-ui-side-list .lisu-side-list-item {
  border-bottom: 1px solid #898989;
  padding: 8px;
  display: block;
}

.lisu-ui-side-list .lisu-side-list-item:hover {
  cursor: pointer;
  background-color: #0000001a;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lisu-smaller {
  font-size: 80%;
}

.lisu-small-label {
  color: #808080a8;
  text-transform: uppercase;
  font-size: 80%;
}

.ld-app-date-picker-wrapper {
  position: relative;
}

.ld-app-date-picker {
  z-index: 10000;
  background-color: #fff;
  border: 1px solid #898989;
  border-bottom-width: 3px;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  left: 0;
  box-shadow: 0 4px 8px #00000080;
}

.ld-app-date-picker .ld-app-calendar {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.ld-app-date-picker .ld-app-calendar th {
  border-bottom: 1px solid gray;
  width: 12.5%;
}

.ld-app-date-picker .ld-app-calendar td {
  font-size: 12px;
  font-weight: bold;
}

.ld-app-date-picker .ld-app-calendar td span {
  border-radius: 50%;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 2px;
  line-height: 100%;
  display: grid;
}

.ld-app-date-picker .ld-app-calendar td span:hover {
  cursor: pointer;
  background-color: #0003;
}

.ld-app-date-picker .ld-app-calendar td span[data-today="true"] {
  border: 1px dashed #000;
}

.ld-app-date-picker .ld-app-calendar td span[data-active="true"] {
  color: #fff;
  background-color: #00bfff;
}

.ld-app-date-picker .ld-app-calendar tr td:first-child {
  border-right: 1px solid gray;
  padding: 2px 8px;
  font-size: 70%;
  font-weight: 400;
}

.lisu-custom-check {
  vertical-align: middle;
  margin-top: -.15em;
  font-size: 20px;
  display: inline-block;
}

.lisu-custom-check:hover {
  transform: scale(1.1);
}

.lisu-progress-step {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}

.lisu-progress-step-label {
  cursor: pointer;
}

.lisu-progress-step-line {
  width: 100%;
  height: 3px;
  transform: translateX(50%)translateY(17px);
}

.lisu-hoverable-item {
  -webkit-user-select: none;
  user-select: none;
  padding: 4px;
  display: block;
}

.lisu-hoverable-item:not(:last-child) {
  border-bottom: 1px solid #0000001a;
}

.lisu-hoverable-item:hover {
  cursor: pointer;
  background-color: #0003;
}
/*# sourceMappingURL=index.304002a6.css.map */
